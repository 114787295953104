'use strict';

import { ApiClient } from './components/ApiClient';

class Ping {
    getClassName () { return 'Ping'; }

    constructor () {
        this.apiClient = new ApiClient();
        if (window.navigationHandler) {
            this.addNavigationHandlerEvents();
        } else {
            window.addEventListener('navigationhandler:init', () => this.addNavigationHandlerEvents());
        }
    }

    addNavigationHandlerEvents () {
        window.navigationHandler.on('ready', () => this.handlePageView());
        window.navigationHandler.on('render', () => this.handlePageView());
    }

    async handlePageView () {
        const requestOptions = {
            pageid: window.antenne.config.pageid,
        };
        this.apiClient.post('ping', requestOptions);
    }
}

new Ping();
